import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';

import { FormulaSimpleBannerProps } from './models';

import './FormulaSimpleBanner.scss';

const FormulaSimpleBanner: FC<FormulaSimpleBannerProps> = ({
  formulaSimpleBannerData: { image, text, altText },
}) => (
  <div className="formula-banner-container">
    <div className="formula-banner-container__content-container">
      <div className="formula-banner-container__image-container">
        <Image imageData={image} alt={altText} />
      </div>
      <div className="formula-banner-container__text-container">
        <DangerouslySetInnerHtml html={text} />
      </div>
    </div>
  </div>
);

export default FormulaSimpleBanner;
