import React, { FC } from 'react';
import { Link } from 'gatsby';
import Carousel from 'nuka-carousel';

import Button from 'common/Button';
import { VisualType, VisualVariant } from 'common/Button/enums';
import Image from 'common/Image';

import { CarouselSectionProps } from './models';

import './CarouselSection.scss';

const CarouselSection: FC<CarouselSectionProps> = ({ carousel }) => {
  const { carouselCards } = carousel;

  return (
    <div className="carousel">
      <Carousel
        cellAlign="center"
        slidesToShow={1.3}
        heightMode="current"
        scrollMode="page"
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
        defaultControlsConfig={{
          pagingDotsClassName: 'carousel__dots',
        }}
      >
        {carouselCards.map(({ image, title, description, button }, index) => (
          <div
            className="carousel__card"
            key={title}
            data-testid="card"
            role="slider"
            aria-valuenow={index}
          >
            <div className="carousel__image">
              <Image imageData={image} alt={title} data-testid="imageCard" />
            </div>
            <div className="carousel__content">
              {button?.url ? (
                <Link to={button.url}>
                  <h2 className="carousel__title">{title}</h2>
                </Link>
              ) : (
                <h2 className="carousel__title">{title}</h2>
              )}

              <p className="carousel__description">{description}</p>
              <div className="carousel__flex" />
              {button ? (
                <div className="carousel__button">
                  <Link to={button?.url}>
                    <Button
                      visualType={VisualType.Squared}
                      visualVariant={VisualVariant.Pink}
                      ariaLabel={button?.name}
                    >
                      {button?.name}
                    </Button>
                  </Link>
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselSection;
