import React, { FC } from 'react';
import { Link } from 'gatsby';

import Image from 'common/Image';

import { HomepageTopBannerNavigationProps } from './models';

import './HomepageTopBannerNavigation.scss';

const HomepageTopBannerNavigation: FC<HomepageTopBannerNavigationProps> = ({ navigationData }) => (
  <div className="home-page-top-banner-navigation">
    {navigationData.map(
      ({ navigationTitle, navigationLink, navigationImage, navigationAltText }) => (
        <Link to={navigationLink.url} key={navigationTitle}>
          <div className="home-page-top-banner-navigation__item">
            <Image imageData={navigationImage} alt={navigationAltText} />
            <span>{navigationTitle}</span>
          </div>
        </Link>
      )
    )}
  </div>
);

export default HomepageTopBannerNavigation;
