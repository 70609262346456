import React, { FC } from 'react';
import { Link } from 'gatsby';

import Button from 'common/Button';
import { VisualType, VisualVariant } from 'common/Button/enums';
import Image from 'common/Image';
import HomepageTopBannerNavigation from 'components/HomepageTopBannerNavigation';

import { HomepageTopBannerProps } from './models';

import './HomepageTopBanner.scss';

const HomepageTopBanner: FC<HomepageTopBannerProps> = ({
  homepageTopBannerData: {
    bannerImage,
    bannerTitle,
    bannerDescription,
    button,
    navigation,
    altText,
  },
}) => (
  <>
    <div className="top-banner-container">
      <div className="top-banner-container__background">
        <Image imageData={bannerImage} alt={altText} />
        <div className="top-banner-container__container">
          <div className="top-banner-container__content-items">
            <h1 className="top-banner-container__title">
              {bannerTitle}
              {bannerDescription ? (
                <span className="top-banner-container__description">{bannerDescription}</span>
              ) : null}
            </h1>

            {button ? (
              <Link to={button.url}>
                <Button
                  ariaLabel={button.name}
                  visualType={VisualType.Rounded}
                  visualVariant={VisualVariant.Transparent}
                >
                  {button.name}
                </Button>
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </div>
    <HomepageTopBannerNavigation navigationData={navigation} />
  </>
);

export default HomepageTopBanner;
