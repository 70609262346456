import React, { FC } from 'react';
import Image from 'gatsby-theme-husky/src/common/Image';
import SvgImageIcon from 'gatsby-theme-husky/src/common/SvgImageIcon';
import { WomanStoryCardProps } from 'gatsby-theme-husky/src/components/WomanStoryCard/models';
import { DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';
import classNames from 'classnames';

import 'gatsby-theme-husky/src/components/WomanStoryCard/WomanStoryCard.scss';
import './WomanStoryCardExtends.scss';

const WomanStoryCard: FC<WomanStoryCardProps> = ({
  womanStoryCard: { title, womanName, image, story, cardColor, icon },
}) => (
  <div
    className={classNames(
      'woman-story-card',
      `woman-story-card--${cardColor.label?.toLowerCase()}-theme`
    )}
  >
    <div className="woman-story-card__card-container">
      <div className="woman-story-card__woman-photo">
        <Image imageData={image} alt={womanName} />
      </div>
      <div className="woman-story-card__content">
        <span className="woman-story-card__title">{title}</span>
        <span className="woman-story-card__woman-name">{womanName}</span>
      </div>
    </div>
    <SvgImageIcon icon={icon} iconAlt={title} customClassName="woman-story-card__icon" />
    <DangerouslySetInnerHtml className="woman-story-card__woman-story" html={story} />
  </div>
);

export default WomanStoryCard;
