import React, { FC } from 'react';
import { Link } from 'gatsby';

import Button from 'common/Button';
import { VisualType, VisualVariant } from 'common/Button/enums';
import ProductDisplaySectionCard from 'components/ProductDisplaySectionCard';

import { ProductDisplaySectionProps } from './models';

import './ProductDisplaySection.scss';

const ProductDisplaySection: FC<ProductDisplaySectionProps> = ({
  productDisplaySectionData: { sectionTitle, otherProductsButton, products },
}) => (
  <div className="promo-product-section">
    <h2 className="promo-product-section__title">{sectionTitle}</h2>
    {otherProductsButton ? (
      <Link to={otherProductsButton.url}>
        <Button
          ariaLabel={otherProductsButton.name}
          visualType={VisualType.Rounded}
          visualVariant={VisualVariant.Pink}
        >
          {otherProductsButton.name}
        </Button>
      </Link>
    ) : null}
    <div className="promo-product-section__products">
      {products?.map((productCard, index) => (
        <ProductDisplaySectionCard
          key={productCard.typeTitle}
          productDisplaySectionCardData={{
            ...productCard,
            visualVariant: index === 0 ? VisualVariant.Blue : VisualVariant.Pink,
          }}
        />
      ))}
    </div>
  </div>
);

export default ProductDisplaySection;
