import React, { FC } from 'react';
import { useId } from 'react-id-generator';

import { DangerouslySetInnerHtml } from 'layout';

import WomanStoryCard from '../WomanStoryCard';
import { WomenStoriesSectionProps } from './models';

import './WomenStoriesSection.scss';

const WomenStoriesSection: FC<WomenStoriesSectionProps> = ({
  womenStoriesSection: { title, description, sectionDescription, womanStoryCardPicker },
}) => {
  const idList = useId(womanStoryCardPicker.length);

  return (
    <div className="women-stories-section">
      <DangerouslySetInnerHtml className="women-stories-section__title" html={title} />
      <DangerouslySetInnerHtml className="women-stories-section__description" html={description} />
      <span className="women-stories-section__section-description">{sectionDescription}</span>
      <div className="women-stories-section__card-container">
        {womanStoryCardPicker.map((storyCard, index) => (
          <WomanStoryCard womanStoryCard={storyCard} key={idList[index]} />
        ))}
      </div>
    </div>
  );
};

export default WomenStoriesSection;
