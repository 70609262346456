import React, { FC } from 'react';

import Image from 'common/Image';

import { ProductBenefitsBannerProps } from './models';

import './ProductBenefitsBanner.scss';

const ProductBenefitsBanner: FC<ProductBenefitsBannerProps> = ({
  productBenefitsBanner: { title, descriptions, backgroundImage, altText },
}) => (
  <div className="product-benefits-banner">
    <Image
      className="product-benefits-banner__background"
      imageData={backgroundImage}
      alt={altText}
    />
    <div className="product-benefits-banner__wrapper">
      <ul>
        {descriptions.map(({ title: header, content }) => (
          <li key={header}>
            <h2 className="product-benefits-banner__benefit-title">{header}</h2>
            <p className="product-benefits-banner__benefit-content">{content}</p>
          </li>
        ))}
      </ul>
      <h2 className="product-benefits-banner__title">{title}</h2>
    </div>
  </div>
);

export default ProductBenefitsBanner;
