import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import CarouselSection from 'components/CarouselSection';
import FormulaSimpleBanner from 'components/FormulaSimpleBanner';
import GallerySection from 'components/GallerySection';
import HomepageTopBanner from 'components/HomepageTopBanner';
import ProductBenefitsBanner from 'components/ProductBenefitsBanner';
import ProductDisplaySection from 'components/ProductDisplaySection';
import ProductSpecificationSection from 'components/ProductSpecificationSection';
import WomenStoriesSection from 'components/WomenStoriesSection';

const HomePage: FC<HomepageTypes.HomepageProps> = ({
  data: {
    homepage: {
      urls,
      seo,
      productBenefitsBanner,
      homepageTopBanner,
      gallery,
      womenStoriesSection,
      carousel,
      productDisplaySection,
      productSpecificationSection,
      formulaSimpleBanner,
    },
  },
}) => (
  <Layout headerTransparent seo={seo} urls={urls}>
    <HomepageTopBanner homepageTopBannerData={homepageTopBanner} />
    <WomenStoriesSection womenStoriesSection={womenStoriesSection} />
    <FormulaSimpleBanner formulaSimpleBannerData={formulaSimpleBanner} />
    <ProductDisplaySection productDisplaySectionData={productDisplaySection} />
    <ProductBenefitsBanner productBenefitsBanner={productBenefitsBanner[0]} />
    <ProductSpecificationSection productSpecificationSection={productSpecificationSection} />
    <CarouselSection carousel={carousel} />
    <GallerySection gallery={gallery} />
  </Layout>
);

export const query = graphql`
  query ($lang: String) {
    homepage(lang: { eq: $lang }) {
      urls {
        ...UrlsFragment
      }
      seo {
        ...SEOStructureFragment
      }
      carousel {
        ...CarouselFragment
      }
      homepageTopBanner {
        ...HomepageTopBannerFragment
      }
      productBenefitsBanner {
        ...ProductBenefitsBannerFragment
      }
      gallery {
        ...GalleryFragment
      }
      productDisplaySection {
        ...ProductDisplaySectionFragment
      }
      womenStoriesSection {
        ...WomenStoriesSectionFragment
      }
      productSpecificationSection {
        ...ProductSpecificationSectionFragment
      }
      formulaSimpleBanner {
        ...FormulaSimpleBannerFragment
      }
    }
  }
`;

export default HomePage;
