import React, { FC, useContext } from 'react';
import { useId } from 'react-id-generator';
import { ScreenRecognitionContext } from '@phx-husky/screen-recognition';

import Image from 'common/Image';

import { GallerySectionProps } from './models';

import './GallerySection.scss';

const GallerySection: FC<GallerySectionProps> = ({ gallery: { title, images } }) => {
  const imageId = useId(images.length);
  const { isDesktop, isXl } = useContext(ScreenRecognitionContext);
  const arrayOfElements = isDesktop || isXl ? images : images.slice(0, 4);

  return (
    <div className="gallery">
      <h2 className="gallery__title">{title}</h2>
      <div className="gallery__items">
        {arrayOfElements.map(({ image, altText }, index) => (
          <div className="gallery__item" key={imageId[index]}>
            <Image imageData={image} alt={altText} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default GallerySection;
