import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import Button from 'common/Button';
import { VisualType } from 'common/Button/enums';
import Image from 'common/Image';

import { ProductDisplaySectionCardProps } from './models';

import './ProductDisplaySectionCard.scss';

const ProductDisplaySectionCard: FC<ProductDisplaySectionCardProps> = ({
  productDisplaySectionCardData: {
    image,
    altText,
    typeTitle,
    formatTitle,
    showMoreButton,
    visualVariant,
  },
}) => (
  <div className={classNames('product-card', `product-card--${visualVariant}`)}>
    <h3 className="product-card__description">{formatTitle}</h3>
    <div className="product-card__content">
      <Image imageData={image} alt={altText} />

      {showMoreButton?.url ? (
        <Link className="product-card__product-name" to={showMoreButton.url}>
          {typeTitle}
        </Link>
      ) : (
        <p className="product-card__product-name">{typeTitle}</p>
      )}

      {showMoreButton ? (
        <Link to={showMoreButton.url}>
          <Button
            ariaLabel={showMoreButton.name}
            visualType={VisualType.Squared}
            visualVariant={visualVariant}
          >
            {showMoreButton.name}
          </Button>
        </Link>
      ) : null}
    </div>
  </div>
);

export default ProductDisplaySectionCard;
